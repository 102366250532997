
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import getCurrentQuery from '@/utils/querystrings'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
@Component({
  name: 'alipaylogin',
  components: {
  },
  mixins: [GlobalMixin]
})
export default class alipayloginPage extends Vue {
  public async created () {
    const isSuccess = getCurrentQuery('is_success') || ''
    const name = getCurrentQuery('real_name') || ''
    if (isSuccess === 'T') {
      const data:any = {
        auth_code: getCurrentQuery('auth_code') || '',
        state: getCurrentQuery('state') || '',
        real_name: decodeURI(name),
        user_id: getCurrentQuery('user_id') || ''
      }
      try {
        const res = await httpHelper.getAuthLogin(data, 'authorize/alipay')
        this.goTo(res, 9, 6)
      } catch (err) {
        this.goTo(err, 9, 6)
        this.$toasted.show(err.msg)
        setTimeout(() => {
          location.replace('/sms')
        }, 3000)
      }
    } else {
      this.$toasted.show('授权失败')
      setTimeout(() => {
        location.replace('/sms')
      }, 3000)
    }
  }
}
